<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Merge Code</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="500">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Merge Code Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Code-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_code"
                    label="Code"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Section-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="mergeCodeSectionsStore.mergeCodeSectionsValueList"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_name"
                    item-value="id"
                    v-model="f_section"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "mergeCodesStore/getEntryField",
  mutationType: "mergeCodesStore/updateEntryField"
});

export default {
  name: "MergeCodeEntry",
  computed: {
    ...mapState({
      mergeCodesStore: state => state.mergeCodesStore,
      valueListsStore: state => state.valueListsStore,
      mergeCodeSectionsStore: state => state.mergeCodeSectionsStore
    }),
    ...mapFields(["show", "valid", "mode", "id", "d_code", "f_section", "f_status"])
  },
  data() {
    return {};
  },
  methods: {
    async newEntry() {
      await this.$store.dispatch("mergeCodeSectionsStore/valueList");
      this.valid = true;
      this.mode = 1;
      this.id = "";
      this.d_code = "";
      this.f_section = "";
      this.f_status = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["mergeCodesStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("mergeCodesStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("mergeCodesStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
